import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '~/shared/components/Button/Button';
import { Text } from '$shared/components/Text/Text';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import Grid from '~/shared/components/Grid';
import { mq } from '~/lib/helpers/media-query.helper';
import { breakpoints } from '~/theme/breakpoints';
import { useLocation } from 'react-use';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useStats } from '../../hooks/useStats';

const ButtonWrapper = styled.div(({ theme }) => ({
    padding: theme.space[3],
    gridColumn: 'span 2',
    [breakpoints.xs]: {
        marginRight: 0,
    },
    [breakpoints.sm]: {
        padding: theme.space[4],
        marginRight: 0,
    },
    [breakpoints.md]: {
        gridColumn: 'unset',
    },
    [mq('lg')]: {
        padding: theme.space[4],
        gridColumn: '4 / 6',
    },
}));

const Wrapper = styled('section')(({ theme }) => ({
    padding: 0,
    [breakpoints.sm]: {
        padding: `0 ${theme.space[3]}`,
    },
    [breakpoints.md]: {
        padding: `0 ${theme.space[6]}`,
    },
}));

interface PaginationProps {
    showMore: () => void;
    hitsLoaded: number;
    isLoading?: boolean;
}

export const Pagination: FC<PaginationProps> = ({ hitsLoaded, showMore, isLoading }) => {
    const { translate } = useTranslation();
    const location = useLocation();
    const { data: frame } = useFrame();
    const { filterEvent } = useEvents(frame);
    const stats = useStats();

    return (
        <Wrapper>
            <Grid
                flow="row"
                rowGap="small"
                columns="repeat(4, 1fr)"
                columnGap="small"
                breakpoints={{
                    [mq('xs')]: {
                        columns: 'repeat(4,1fr)',
                        rowGap: 'none',
                        columnGap: 'none',
                    },
                    [mq('md', 'lg')]: {
                        columns: 'repeat(3, 1fr)',
                        columnGap: 'small',
                        rowGap: 'small',
                    },
                    [mq('lg')]: {
                        columns: 'repeat(8, 1fr)',
                        columnGap: 'medium',
                        rowGap: 'medium',
                    },
                }}
            >
                <div />

                <ButtonWrapper>
                    <Button
                        variant="plp"
                        disabled={isLoading}
                        onClick={() => {
                            filterEvent('NextPage', location.pathname || '/');
                            showMore();
                        }}
                        css={{ textTransform: 'uppercase', width: '100%' }}
                    >
                        {!!stats?.hitsPerPage &&
                            translate('plp.loadMoreText', {
                                amount: stats?.hitsPerPage?.toString(),
                            })}
                    </Button>

                    <Text
                        variant="productInfo"
                        css={(theme) => ({
                            width: '100%',
                            textAlign: 'center',
                            marginTop: theme.space[2],
                        })}
                    >
                        {translate('plp.loadMoreDescription', {
                            amount: hitsLoaded?.toString(),
                            total: stats.nbHits?.toString(),
                        })}
                    </Text>
                </ButtonWrapper>

                <div />
            </Grid>
        </Wrapper>
    );
};
