import React, { FC, useCallback, useState } from 'react';
import { RefinementDropdown } from './RefinementDropdown';
import { RefinementProps } from './RefinementProps';
import { RefinementPrice } from './RefinementPrice';
import { RefinementSlider } from './RefinementSlider';
import RefinementList from './RefinementList';

type RefinementPriceDropdown = RefinementProps & {
    searchable?: boolean;
    showCount?: boolean;
};

export const RefinementPriceDropdown: FC<RefinementPriceDropdown> = ({
    attribute,
    searchable,
    showCount = true,
    label,
}: RefinementPriceDropdown) => {
    const [searchValue, setSearchValue] = useState('');
    const onClose = (isOpen: boolean) => {
        if (!isOpen) setSearchValue('');
    };

    const onRefine = useCallback(() => {
        setSearchValue('');
    }, []);

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    return (
        <RefinementDropdown label={label} onChange={onClose}>
            <RefinementSlider attribute="cust_effectivePrice" />
            <RefinementPrice attribute="cust_effectivePrice" />
            <RefinementList
                showCount={showCount}
                attribute={attribute}
                isSearchable={searchable}
                searchText={searchValue}
                onSearch={handleSearch}
                onRefine={onRefine}
            />
        </RefinementDropdown>
    );
};
