import React, { useState, useEffect, FC, memo } from 'react';
import { InfiniteHitsCache } from 'instantsearch.js/es/connectors/infinite-hits/connectInfiniteHits';
import { createInfiniteHitsSessionStorageCache } from 'instantsearch.js/es/lib/infiniteHitsCache';
import { useInfiniteHits, useInstantSearch, usePagination } from 'react-instantsearch';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { Button } from '~/shared/components';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { EventIdentifier, useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import HitsHeader from '../HitsHeader/HitsHeader';
import { Pagination } from '../Pagination/Pagination';
import { ProductList } from '../ProductList/ProductList';
import { _transformProductDetailsToDataLayer } from '~/shared/hooks/useEvents/helpers';
import { ProductListActions, ProductListWrapper } from './styled';
import useTransitionedValue from '~/shared/hooks/useTransitionedValue';
import { useGetTotalHits } from '~/shared/utils/useGetTotalHits';

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

interface InfiniteHitsProps {
    identifier: EventIdentifier;
    query?: string | string[];
    itemsPerpage?: number;
}

const InfiniteHits: FC<InfiniteHitsProps> = ({ identifier, query, itemsPerpage }) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const { productsImpressionsEvent } = useEvents(frame);
    const { isPlusMember } = useCustomer();
    const { setTotalHits } = useGetTotalHits();

    const [isEventTriggered, setIsEventTriggered] = useState(false);

    const { nbHits: totalHits } = usePagination();
    const { status } = useInstantSearch();
    const { hits, isLastPage, isFirstPage, showMore, showPrevious } =
        useInfiniteHits<TProductDetails>({
            cache: sessionStorageCache as unknown as InfiniteHitsCache<TProductDetails>,
            escapeHTML: false,
        });

    const { value: productHits, isPending } = useTransitionedValue(hits);
    const isLoading = status === 'loading' || status === 'stalled' || isPending;

    useEffect(() => {
        // Only run this when this event hasn't been triggered yet.
        if (isEventTriggered) return;

        if (hits.length) {
            const transformedItems = hits.map((hit, index) =>
                _transformProductDetailsToDataLayer(hit, 1, index, isPlusMember),
            );

            productsImpressionsEvent('ProductList', 'ProductList', transformedItems);

            setIsEventTriggered(true);
        }
    }, [hits, isPlusMember, query]);

    useEffect(() => {
        if (totalHits) {
            setTotalHits(totalHits);
        }
    }, [totalHits]);

    return (
        <>
            {!!totalHits && <HitsHeader totalHits={totalHits} />}

            <ProductListWrapper>
                {!isFirstPage && (
                    <ProductListActions>
                        <Button variant="plp" onClick={showPrevious}>
                            {translate('plp.loadPreviousText')}
                        </Button>
                    </ProductListActions>
                )}

                <ProductList
                    hits={productHits}
                    identifier={identifier}
                    itemsPerPage={itemsPerpage}
                />

                {!isLastPage && (
                    <Pagination
                        showMore={showMore}
                        hitsLoaded={hits.length}
                        isLoading={isLoading}
                    />
                )}
            </ProductListWrapper>
        </>
    );
};

export default memo(InfiniteHits);
