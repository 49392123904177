import { usePage } from '~/templates/pages';
import { useStats } from '../../hooks/useStats';
import { useInstantSearchContext } from 'react-instantsearch';

export const SSRPagination = ({ asPath }: { asPath?: string }) => {
    const { data } = usePage();
    if (!data?.plpResult) return null;
    const { nbPages } = useStats();
    const instantSearchContext = useInstantSearchContext();
    const productsUiState = instantSearchContext?._initialUiState?.['products'];
    const totalPage = data.plpResult.serverState?.initialResults.products.results.map(
        (item) => item.nbPages
    );
    const totalPages = totalPage[0];
    const page = productsUiState.page ?? 1;
    if (
        page === nbPages ||
        nbPages == 0 ||
        page >= totalPages ||
        instantSearchContext.renderState?.products?.infiniteHits?.isLastPage
    )
        return null;

    return (
        <a style={{ display: 'none' }} href={`${asPath?.split('?')[0]}?page=${Number(page) + 1}`}>
            {asPath?.split('?')[0]}
        </a>
    );
};
