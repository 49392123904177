import React, { FC, useCallback, useState } from 'react';
import { RefinementDropdown } from './RefinementDropdown';
import RefinementList from './RefinementList';
import { RefinementProps } from './RefinementProps';

type RefinementListDropdown = RefinementProps & {
    searchable?: boolean;
    showCount?: boolean;
};

export const RefinementListDropdown: FC<RefinementListDropdown> = ({
    attribute,
    searchable,
    showCount = true,
    label,
}: RefinementListDropdown) => {
    const [searchValue, setSearchValue] = useState('');

    const onClose = useCallback((isOpen: boolean) => {
        if (!isOpen) setSearchValue('');
    }, []);

    const onRefine = useCallback(() => {
        setSearchValue('');
    }, []);

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    return (
        <RefinementDropdown label={label} onChange={onClose}>
            <RefinementList
                showCount={showCount}
                attribute={attribute}
                isSearchable={searchable}
                searchText={searchValue}
                onSearch={handleSearch}
                onRefine={onRefine}
            />
        </RefinementDropdown>
    );
};
