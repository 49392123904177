import React, { FC } from 'react';
import { DropDownItemButton, DropdownItemContainer, DropdownItemText } from './styled';
import { useTranslation } from '~/shared/utils/translation';

type Props = {
    onClick: () => void;
    isExpanded: boolean;
    filterCount: number;
};

export const FilterDropdownExpand: FC<Props> = ({ isExpanded, onClick, filterCount }) => {
    const { translate } = useTranslation();

    return (
        <DropdownItemContainer>
            <DropDownItemButton onClick={onClick} fullWidth variant="plain" isRefined={false}>
                <DropdownItemText>
                    <b>
                        {isExpanded
                            ? translate('plp.showLess')
                            : translate('plp.showMore', { count: filterCount?.toString() })}
                    </b>
                </DropdownItemText>
            </DropDownItemButton>
        </DropdownItemContainer>
    );
};
