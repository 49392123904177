import styled from '@emotion/styled';
import CloseIcon from '$icons/close.svg';

export const StyledList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: `${theme.space[2]} 0`,
}));

const StyledLi = styled.li(({ theme }) => ({
    ...theme.mixins.useTextStyle('small'),
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.space[2],
    padding: `${theme.space[1]} ${theme.space[2]}`,
    borderRadius: theme.sizes.cornerRadius,
    cursor: 'pointer',
    fontWeight: '300',

    '&:not(:last-of-type)': {
        marginRight: theme.space[2],
    },
}));

export const StyledClearAll = styled(StyledLi)(({ theme }) => ({
    background: theme.colors.black,
    color: theme.colors.white,
}));

export const StyledFacet = styled(StyledLi)(({ theme }) => ({
    border: `1px solid ${theme.colors.brownLight}`,
    background: theme.colors.grey10,
    color: theme.colors.black,

    '&:hover': {
        borderColor: theme.colors.brownMedium,
    },
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
    width: 16,
    height: 16,
    marginLeft: `-${theme.space[1]}`,
    marginRight: theme.space[1],
    padding: theme.space[1],
}));
