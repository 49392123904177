import { memo, useCallback, useMemo, useState } from 'react';
import { useDynamicWidgets, UseDynamicWidgetsProps } from 'react-instantsearch';
import { TranslationKey } from '~/lib';
import Flex from '~/shared/components/Flex';
import { useTranslation } from '~/shared/utils/translation';
import { FilterContainer, FilterIcon, StyledButton } from './styled';
import {
    RefinementStockStatus,
    RefinementToggle,
    RefinementListDropdown,
    RefinementNewsDropdown,
    RefinementListColor,
    RefinementPriceDropdown,
} from './components';
import { colorSwatchKey } from '../../hooks/useColorSwatches';

type FilterListType = UseDynamicWidgetsProps & {
    maxWidgets?: number;
    defaultShowAllWidgets?: boolean;
};

const preDefined = { stockStatus: 'preDefinedStockStatus', newsToggles: 'preDefinedNewsToggles' };

const FilterList = (props: FilterListType) => {
    const { translate } = useTranslation();
    const {
        maxWidgets = 7,
        defaultShowAllWidgets = false,
        facets = ['*'],
        maxValuesPerFacet = 1000,
        transformItems,
    } = props;

    const { attributesToRender: allAttributes } = useDynamicWidgets({
        maxValuesPerFacet,
        facets,
        transformItems,
    });

    const [showAllWidgets, setShowAllWidgets] = useState(defaultShowAllWidgets);

    const attributesToRender = useMemo(
        () => [preDefined.stockStatus, preDefined.newsToggles, ...allAttributes],
        [allAttributes],
    );

    const attributesToShow = useMemo(
        () => attributesToRender.slice(0, showAllWidgets ? undefined : maxWidgets),
        [showAllWidgets, maxWidgets, attributesToRender],
    );

    const renderAttributes = useCallback(
        (attributeName: string) => {
            switch (attributeName) {
                case colorSwatchKey:
                    return null;

                case preDefined.newsToggles:
                    return (
                        <RefinementNewsDropdown
                            key={attributeName}
                            label={translate('plp.facet.campaigns')}
                            attribute={attributeName}
                        />
                    );

                case preDefined.stockStatus:
                    return (
                        <RefinementStockStatus
                            key={attributeName}
                            label={translate('plp.facet.cust_storeIdsWithStock')}
                            attribute={attributeName}
                        />
                    );

                case 'cust_sole_representation':
                case 'cust_isNew':
                case 'cust_spec_allergyFriendly':
                case 'cust_spec_personalization':
                    return (
                        <RefinementToggle
                            key={attributeName}
                            attribute={attributeName}
                            label={translate(`plp.facet.${attributeName}` as TranslationKey)}
                        />
                    );

                case 'cust_priceRanges':
                    return (
                        <RefinementPriceDropdown
                            key={attributeName}
                            attribute={attributeName}
                            label={translate(`plp.facet.${attributeName}` as TranslationKey)}
                        />
                    );

                case 'cust_color':
                    return (
                        <RefinementListColor
                            searchable
                            key={attributeName}
                            attribute={attributeName}
                            label={translate(`plp.facet.${attributeName}` as TranslationKey)}
                        />
                    );

                default:
                    return (
                        <RefinementListDropdown
                            key={attributeName}
                            searchable
                            attribute={attributeName}
                            label={translate(`plp.facet.${attributeName}` as TranslationKey)}
                        />
                    );
            }
        },
        [translate],
    );

    const renderedAttributes = useMemo(
        () => attributesToShow.map(renderAttributes),
        [attributesToShow, renderAttributes],
    );

    const toggleAllWidgets = () => setShowAllWidgets(!showAllWidgets);

    const showAllWidgetsText = !showAllWidgets
        ? translate('plp.allFilters')
        : translate('plp.fewerFilters');

    return (
        <FilterContainer
            gap="extrasmall"
            alignItems="flex-start"
            wrap="wrap"
            maxWidgets={maxWidgets}
            showAll={showAllWidgets}
        >
            {renderedAttributes}

            {attributesToRender.length > maxWidgets && (
                <StyledButton variant="inverse-plp" small onClick={toggleAllWidgets}>
                    <Flex gap="extrasmall" justifyContent="center" alignItems="center">
                        <div css={{ whiteSpace: 'nowrap' }}>{showAllWidgetsText}</div>
                        <FilterIcon />
                    </Flex>
                </StyledButton>
            )}
        </FilterContainer>
    );
};

export default memo(FilterList);
