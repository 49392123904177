import { FC, Fragment, memo, useCallback } from 'react';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch';
import {
    CurrentRefinementsConnectorParamsRefinement,
    CurrentRefinementsConnectorParamsItem,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';
import { TranslationKey } from '~/lib';
import { useTranslation } from '~/shared/utils/translation';
import { StyledList, StyledFacet, StyledCloseIcon, StyledClearAll } from './SelectedFacets.styles';
import { useDeferredFunction } from '~/shared/hooks/useDeferredFunction';

interface SelectedFacetItemProps {
    item: CurrentRefinementsConnectorParamsItem;
}

const SelectedFacetItem: FC<SelectedFacetItemProps> = ({ item }) => {
    const { translate } = useTranslation();
    const { indexName, refine, refinements } = item;

    const getRefinementLabel = useCallback(
        (refinement: CurrentRefinementsConnectorParamsRefinement) => {
            if (refinement.attribute === 'cust_in_stock_web') {
                return translate('plp.inStock');
            }

            if (['true', 'false'].includes(refinement.label)) {
                return translate(`plp.facet.${refinement.attribute}` as TranslationKey);
            }

            return refinement.label;
        },
        [translate],
    );

    const deferredRefine = useDeferredFunction(refine);

    const removeRefinement = useCallback(
        (refinement: CurrentRefinementsConnectorParamsRefinement) => {
            deferredRefine(refinement);
        },
        [],
    );

    return (
        <Fragment>
            {refinements.map((refinement) => (
                <StyledFacet
                    key={`${indexName}-${refinement.value}`}
                    onClick={() => removeRefinement(refinement)}
                >
                    <StyledCloseIcon />
                    {getRefinementLabel(refinement)}
                </StyledFacet>
            ))}
        </Fragment>
    );
};

const MemoizedSelectedFacetItem = memo(SelectedFacetItem);

const SelectedFacets: FC = () => {
    const { translate } = useTranslation();
    const { refine } = useClearRefinements();
    const { items: currentRefinements } = useCurrentRefinements();
    const clearRefinements = useDeferredFunction(refine, []);
    if (!currentRefinements.length) return null;

    return (
        <StyledList>
            <StyledClearAll onClick={clearRefinements}>
                {translate('plp.clearFilter')}
            </StyledClearAll>
            {currentRefinements.map((item) => (
                <MemoizedSelectedFacetItem key={item.attribute} item={item} />
            ))}
        </StyledList>
    );
};

export default memo(SelectedFacets);
