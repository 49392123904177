import styled from '@emotion/styled';

export const ProductListWrapper = styled.div(() => ({
    minHeight: 0,
}));

export const ProductListActions = styled.div(() => ({
    maxWidth: 340,
    margin: '0 auto',
    marginBottom: '1rem',
}));
