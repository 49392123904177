import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Text } from '~/shared/components';
import Grid from '~/shared/components/Grid/Grid';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { mq } from '~/lib';
import { breakpoints } from '~/theme/breakpoints';
import { useProductHandler } from '../../../../services/search/useProductHandler';
import { useTranslation } from '~/shared/utils/translation';
import { useWishlist } from '~/features/commerce-api/hooks/useWishlist';
import { EventIdentifier } from '~/shared/hooks/useEvents';
import { useInstantSearch } from 'react-instantsearch';
import ProductHit from './ProductHit';

interface ProductListProps {
    identifier: EventIdentifier;
    hits: TProductDetails[];
    itemsPerPage?: number;
}

const ProductGridWrapper = styled.section(({ theme }) => ({
    padding: 0,
    [breakpoints.sm]: {
        padding: `0 ${theme.space[3]}`,
    },
    [breakpoints.md]: {
        padding: `0 ${theme.space[6]}`,
    },
}));

export const ProductList: FC<ProductListProps> = ({ hits, identifier }) => {
    const { handleFavourite, isFavorite } = useWishlist();
    const getProductHandler = useProductHandler();
    const { translate } = useTranslation();
    const { status: searchStatus } = useInstantSearch();

    const hasNoResults = hits.length === 0 && searchStatus === 'idle';

    return (
        <ProductGridWrapper>
            <Grid
                flow="row"
                rowGap="small"
                columns="repeat(2, 1fr)"
                columnGap="small"
                breakpoints={{
                    [mq('xs')]: {
                        rowGap: 'none',
                        columnGap: 'none',
                    },
                    [mq('md', 'lg')]: {
                        columns: 'repeat(3, 1fr)',
                        columnGap: 'small',
                        rowGap: 'small',
                    },
                    [mq('lg')]: {
                        columns: 'repeat(4, 1fr)',
                        columnGap: 'medium',
                        rowGap: 'medium',
                    },
                }}
            >
                {hits.map((hit, index) => (
                    <ProductHit
                        key={hit.id}
                        hit={hit}
                        identifier={identifier}
                        position={index}
                        isFavourite={isFavorite}
                        onFavourite={handleFavourite}
                        onBuyButtonClick={getProductHandler}
                    />
                ))}

                {hasNoResults && (
                    <Text variant="navigationHeader">{translate('search.noResults')}</Text>
                )}
            </Grid>
        </ProductGridWrapper>
    );
};
