import { mq } from '$lib';
import { Gutter } from '$shared/components';
import styled from '@emotion/styled';

export const StyledGutter = styled(Gutter)<{ hasRefinements: boolean }>(
    ({ theme, hasRefinements }) => ({
        [mq(0, 'sm')]: {
            display: 'none',
        },

        [mq('sm', 'md')]: {
            marginTop: theme.space[3],
        },
        marginTop: hasRefinements ? theme.space[3] : theme.space[4],
        marginBottom: hasRefinements ? theme.space[3] : theme.space[4],
    })
);
