import dynamic from 'next/dynamic';
import { useCallback, useState } from 'react';
import { useTranslation } from '~/shared/utils/translation';
import FilterRefinementSwitch from '../../FilterRefinementSwitch/FilterRefinementSwitch';
import { RefinementDropdown } from './RefinementDropdown';
import { RefinementProps } from './RefinementProps.d';
import { DropdownItemContainer, DropdownToggleItemContainer } from './styled';
const RefinementList = dynamic(() => import('./RefinementList'), { ssr: false });

type Props = RefinementProps;

export const RefinementStockStatus = ({ label }: Props) => {
    const { translate } = useTranslation();
    const [searchValue, setSearchValue] = useState('');

    const onClose = useCallback((isOpen: boolean) => {
        if (!isOpen) setSearchValue('');
    }, []);

    const onRefine = useCallback(() => {
        setSearchValue('');
    }, []);

    const onSearch = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    return (
        <RefinementDropdown label={label} onChange={onClose}>
            <DropdownItemContainer>
                <DropdownToggleItemContainer>
                    <FilterRefinementSwitch
                        attribute="cust_in_stock_web"
                        label={translate('plp.inStock')}
                        specificFilteringType="Online"
                        value={true}
                    />
                </DropdownToggleItemContainer>
            </DropdownItemContainer>

            <RefinementList
                attribute="cust_storeNamesWithStock"
                isSearchable
                searchText={searchValue}
                onRefine={onRefine}
                onSearch={onSearch}
            />
        </RefinementDropdown>
    );
};
