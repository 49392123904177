import React, { FC } from 'react';
import { useCurrentRefinements } from 'react-instantsearch';
import { StyledGutter } from './FilterBar.styles';

export const FilterBar: FC = ({ children }) => {
    const { items: currentRefinements } = useCurrentRefinements();

    return (
        <StyledGutter gutterSize="xl" hasRefinements={!!currentRefinements.length}>
            {children}
        </StyledGutter>
    );
};
