import { useEffect, useState, useTransition } from 'react';

const useTransitionedValue = <Type>(value: Type) => {
    const [transitionedValue, setTransitionedValue] = useState<Type>(value);

    /**
     * Transitions let you keep the user interface updates responsive even on slow devices.
     * With a Transition, your UI stays responsive in the middle of a re-render.
     *
     * Reference: https://react.dev/reference/react/useTransition
     */
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => setTransitionedValue(value));
    }, [value]);

    return { value: transitionedValue, isPending };
};

export default useTransitionedValue;
