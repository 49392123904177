import { FC, useState } from 'react';
import { useRange, UseRangeProps } from 'react-instantsearch';
import { useDebounce } from 'react-use';
import styled from '@emotion/styled';
import * as Slider from '@radix-ui/react-slider';

const RefinementSliderContainer = styled.div(({ theme }) => ({
    position: 'relative',
    padding: `${theme.space[6]} ${theme.space[3]} ${theme.space[6]}`,
    alignItems: 'center',
}));

const RefinementSliderRoot = styled(Slider.Root)(() => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
}));

const RefinementSliderTrack = styled(Slider.Track)(({ theme }) => ({
    position: 'relative',
    flexGrow: 1,
    backgroundColor: theme.colors.brandOrangeLight,
    height: theme.space[1],
    borderRadius: '9999px',
}));

const RefinementSliderRange = styled(Slider.Range)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.colors.brandOrangeDark,
    height: '100%',
}));

const RefinementSliderThumb = styled(Slider.Thumb)(({ theme }) => ({
    position: 'relative',
    display: 'block',
    width: theme.space[3],
    height: theme.space[3],
    backgroundColor: theme.colors.white,
    fontSize: `0.8rem`,
    border: `1px solid ${theme.colors.borderColor}`,
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.2)',
    borderRadius: '9999px',
    cursor: 'pointer',
    '&:after': {
        position: 'absolute',
        top: `${theme.lineHeights.compact}rem`,
        content: 'attr(aria-valuenow)',
        fontSize: theme.lineHeights.single,
    },
    '&[aria-label="Minimum"]:after': {
        left: 0,
    },
    '&[aria-label="Maximum"]:after': {
        right: 0,
        top: `-${theme.lineHeights.compact}rem`,
    },
}));

export const RefinementSlider: FC<UseRangeProps> = (props: UseRangeProps) => {
    const { start, range, canRefine, refine } = useRange(props);
    const { min, max } = range as { min: number; max: number };
    const [value, setValue] = useState([min ?? 0, max ?? 100]);

    const from = Math.max(min, Number.isFinite(start[0]) ? (start[0] as number) : min);
    const to = Math.min(max, Number.isFinite(start[1]) ? (start[1] as number) : max);

    const handleValueChange = (value: number[]) => {
        if (value[0] < value[1]) {
            setValue(value);
        }
    };

    const handleRefine = (value: number[]) => {
        refine([value[0] ? value[0] : undefined, value[1] ? value[1] : undefined]);
    };

    useDebounce(
        () => {
            setValue([from, to]);
        },
        500,
        [from, to],
    );

    if (!canRefine) return <></>;
    return (
        <RefinementSliderContainer>
            <RefinementSliderRoot
                min={min}
                max={max}
                value={value}
                onValueChange={handleValueChange}
                onValueCommit={handleRefine}
                disabled={!canRefine}
                minStepsBetweenThumbs={1}
            >
                <RefinementSliderTrack>
                    <RefinementSliderRange />
                </RefinementSliderTrack>
                <RefinementSliderThumb />
                <RefinementSliderThumb />
            </RefinementSliderRoot>
        </RefinementSliderContainer>
    );
};
