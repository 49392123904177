import styled from '@emotion/styled';
import { Button } from '~/shared/components';
import BaseFilterIcon from '$icons/filter.svg';
import { ifProp } from 'styled-tools';
import Flex from '~/shared/components/Flex';
import { mq } from '~/lib';
import { RefinementContainer } from './components/styled';

export const StyledButton = styled(Button)<{ isActive?: boolean }>(
    ifProp('isActive', ({ theme }) => ({
        backgroundColor: theme.colors.brownMedium,
    }))
);

export const FilterIcon = styled(BaseFilterIcon)(({ theme }) => ({
    height: '20px !important',
    width: '20px !important',
    color: theme.colors.brownMedium,
}));

export const FilterContainer = styled(Flex)<{ maxWidgets: number, showAll: boolean}>(({ theme, maxWidgets, showAll }) => ({
    [mq(0, 'sm')]: {
        display: 'none',
    },

    '> *': {
        marginBottom: `${theme.space[2]}`,
    },

    [`${RefinementContainer}:nth-child(n+${maxWidgets + 1})`]: {
        display: showAll ? '' : 'none',
    }
}));
