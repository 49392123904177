import { ForwardRefComponent } from 'framer-motion';
import React, { forwardRef } from 'react';
import { ButtonProps } from '~/shared/components';
import { StyledButton } from '../styled';
import { StyledChevron } from './styled';

type BaseFilterDropdownButtonProps = React.PropsWithChildren<unknown> &
    Pick<ButtonProps, 'onClick' | 'disabled'>;

export type FilterDropdownButtonProps = BaseFilterDropdownButtonProps & {
    isOpen?: boolean;
};
type FilterDropdownToggleButtonProps = FilterDropdownButtonProps & {
    isActive?: boolean;
};

export const FilterDropdownButton: ForwardRefComponent<
    HTMLButtonElement,
    FilterDropdownButtonProps
> = forwardRef((props: FilterDropdownButtonProps, ref) => {
    const { isOpen, children, ...rest } = props;
    return (
        <StyledButton
            aria-label="Toggle filter dropdown"
            small
            variant="inverse-plp"
            {...rest}
            ref={ref}
        >
            <span style={{ whiteSpace: 'nowrap' }}>{children}</span>
            <StyledChevron isOpen={isOpen}></StyledChevron>
        </StyledButton>
    );
});

export const FilterToggleDropdownButton = (props: FilterDropdownToggleButtonProps) => {
    const { children, ...rest } = props;
    return (
        <StyledButton
            aria-label="Toggle filter dropdown"
            isActive={props.isActive}
            small
            variant="inverse-plp"
            {...rest}
        >
            <span style={{ whiteSpace: 'nowrap' }}>{children}</span>
        </StyledButton>
    );
};
